
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800;900&display=swap');
//Clas Display
@font-face {
  font-family: 'clash_display';
  src: url('../fonts/clashdisplay-bold-webfont.woff2') format('woff2'),
  url('../fonts/clashdisplay-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;

}
@font-face {
  font-family: 'clash_display';
  src: url('../fonts/clashdisplay-extralight-webfont.woff2') format('woff2'),
  url('../fonts/clashdisplay-extralight-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;

}
@font-face {
  font-family: 'clash_display';
  src: url('../fonts/clashdisplay-light-webfont.woff2') format('woff2'),
  url('../fonts/clashdisplay-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;

}
@font-face {
  font-family: 'clash_display';
  src: url('../fonts/clashdisplay-medium-webfont.woff2') format('woff2'),
  url('../fonts/clashdisplay-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;

}
@font-face {
  font-family: 'clash_display';
  src: url('../fonts/clashdisplay-regular-webfont.woff2') format('woff2'),
  url('../fonts/clashdisplay-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;

}
@font-face {
  font-family: 'clash_display';
  src: url('../fonts/clashdisplay-semibold-webfont.woff2') format('woff2'),
  url('../fonts/clashdisplay-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;

}
//etica Neue
@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelvNeue35_Cyr.eot');
  src: url('../fonts/HelvNeue35_Cyr.eot') format('embedded-opentype'),
  url('../fonts/HelvNeue35_Cyr.woff2') format('woff2'),
  url('../fonts/HelvNeue35_Cyr.woff') format('woff'),
  url('../fonts/HelvNeue35_Cyr.ttf') format('truetype'),
  url('../fonts/HelvNeue35_Cyr.svg#HelvNeue35_Cyr') format('svg');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelvNeue45_Cyr.eot');
  src: url('../fonts/HelvNeue45_Cyr.eot') format('embedded-opentype'),
  url('../fonts/HelvNeue45_Cyr.woff2') format('woff2'),
  url('../fonts/HelvNeue45_Cyr.woff') format('woff'),
  url('../fonts/HelvNeue45_Cyr.ttf') format('truetype'),
  url('../fonts/HelvNeue45_Cyr.svg#HelvNeue45_Cyr') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelvNeue65_Cyr.eot');
  src: url('../fonts/HelvNeue65_Cyr.eot') format('embedded-opentype'),
  url('../fonts/HelvNeue65_Cyr.woff2') format('woff2'),
  url('../fonts/HelvNeue65_Cyr.woff') format('woff'),
  url('../fonts/HelvNeue65_Cyr.ttf') format('truetype'),
  url('../fonts/HelvNeue65_Cyr.svg#HelvNeue65_Cyr') format('svg');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelvNeue75_Cyr.eot');
  src: url('../fonts/HelvNeue75_Cyr.eot') format('embedded-opentype'),
  url('../fonts/HelvNeue75_Cyr.woff2') format('woff2'),
  url('../fonts/HelvNeue75_Cyr.woff') format('woff'),
  url('../fonts/HelvNeue75_Cyr.ttf') format('truetype'),
  url('../fonts/HelvNeue75_Cyr.svg#HelvNeue75_Cyr') format('svg');
  font-weight: 700;
  font-style: normal;
}