.frame{
  display: none;
  opacity: 0;
  height: 100%;
  position: relative;
  &.frame_on{
    opacity: 1;
  }
  &.page {opacity: 0}
  .topLeft{
    position: fixed;
    top: 20px;
    left: 20px;
    width: 93px;
    height: auto;
    svg{
      width: 100%;
      height: auto;
    }
    @media screen and (min-width: 425px) {
      top: 40px;
      left: 50px;
      width: 112px;
    }
    @media screen and (min-width: 768px) {
      top: 60px;
      left: 80px;
      width: 146px;
    }
    @media screen and (min-width: 1024px) {
      top: 80px;
      left: 100px;
      width: 159px;
    }
    @media screen and (min-width: 1440px) {
      top: 100px;
      left: 170px;
      width: 239px;
    }
    #rec{
      fill: white;
      animation-duration: 2s;
      animation-name: clignoter;
      animation-iteration-count: infinite;
      transition: none;
    }
  }
  .topRight{
    position: fixed;
    top: 20px;
    right: 20px;
    width: 156px;
    height: auto;
    svg{
      width: 100%;
      height: auto;
    }
    @media screen and (min-width: 425px) {
      top: 40px;
      right: 50px;
      width: 189px;
    }
    @media screen and (min-width: 768px) {
      top: 60px;
      right: 80px;
      width: 245px;
    }
    @media screen and (min-width: 1024px) {
      top: 80px;
      right: 100px;
      width: 267px;
    }
    @media screen and (min-width: 1440px) {
      top: 100px;
      right: 170px;
      width: 400px;
    }
  }
  .bottomLeft{
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 70px;
    height: auto;
    svg{
      width: 100%;
      height: auto;
    }
    @media screen and (min-width: 425px) {
      bottom: 40px;
      left: 50px;
      width: 85px;
    }
    @media screen and (min-width: 768px) {
      bottom: 60px;
      left: 80px;
      width: 110px;
    }
    @media screen and (min-width: 1024px) {
      bottom: 80px;
      left: 100px;
      width: 120px;
    }
    @media screen and (min-width: 1440px) {
      bottom: 100px;
      left: 170px;
      width: 180px;
    }
  }
  .bottomRight{
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 70px;
    height: auto;
    svg{
      width: 100%;
      height: auto;
    }
    @media screen and (min-width: 425px) {
      bottom: 40px;
      right: 50px;
      width: 85px;
    }
    @media screen and (min-width: 768px) {
      bottom: 60px;
      right: 80px;
      width: 110px;
    }
    @media screen and (min-width: 1024px) {
      bottom: 80px;
      right: 100px;
      width: 120px;
    }
    @media screen and (min-width: 1440px) {
      bottom: 100px;
      right: 170px;
      width: 180px;
    }
  }
  .counter{
    font-family: 'HelveticaNeue';
    font-size: 28px;
    position: fixed;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
  }
}