.contentWrapper {
  .pageWrapper {
    width: 1500px;
    //height: 800px;
    position: fixed;
    //top: 200px;
    bottom: 0;
    right: -12000px;
    opacity: 0;
    transition: all 500ms ease-out, opacity 0s;
    &.visible {
      right: 0;
      left: 0;
      opacity: 1;
      @media screen and (max-width: 1023px) {
        right: 0!important;
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
      }
      @media screen and (min-width: 1024px) {
        left: 235px;
      }
    }
    &.pageContact,&.pageAgence{
      top:150px;
      bottom: auto;
      @media screen and (min-width: 320px) {
      }
      @media screen and (min-width: 425px) {
      }
      @media screen and (min-width: 768px) {
      }
      @media screen and (min-width: 1024px) {
        width: 800px;
        top:auto;
        bottom: 50px;
      }
      @media screen and (min-width: 1440px) {
      }
      h1{
        font-size: 40px;
        line-height: 40px;
        @media screen and (min-width: 425px) {
          font-size: 50px;
          line-height: 50px;
        }
        @media screen and (min-width: 768px) {
          font-size: 60px;
          line-height: 60px;
        }
        @media screen and (min-width: 1024px) {
          font-size: 70px;
          line-height: 70px;
        }
        @media screen and (min-width: 1440px) {
          font-size: 120px;
          line-height: 100px;
        }
      }
      .page_content {
        @media screen and (min-width: 320px) {
          font-size: 16px;
        }
        @media screen and (min-width: 425px) {
          font-size: 16px;
        }
        @media screen and (min-width: 768px) {
          font-size: 20px
        }
        @media screen and (min-width: 1024px) {
          font-size: 24px;
          width: 750px;
        }
        @media screen and (min-width: 1440px) {
          width: 950px;
        }
        @media screen and (min-width: 1920px) {
          width: 1320px;
        }
      }
    }
    h1 {
      color: #fff;
      font-family: "clash_display";
      font-weight: 700;
      position: relative;
      top: 25px;
      padding-bottom: 65px;
      font-size: 50px;
      line-height: 50px;
      @media screen and (min-width: 320px) {
        font-size: 70px;
        line-height: 70px;
      }
      @media screen and (min-width: 425px) {
        font-size: 80px;
        line-height: 80px;
      }
      @media screen and (min-width: 768px) {
        font-size: 100px;
        line-height: 80px;
      }
      @media screen and (min-width: 1024px) {
        font-size: 130px;
        line-height: 100px;
      }
      @media screen and (min-width: 1440px) {
        font-size: 180px;
        line-height: 150px;
      }
    }
    .page_content {
      padding: 30px 0;
      width: 100%;
      border-top: 1px solid white;
      font-size: 24px;
      line-height: 26px;
      border-bottom: 1px solid white;
      font-family: "HelveticaNeue";
      font-weight: 100;
      @media screen and (min-width: 320px) {
        font-size: 28px;
        line-height: 30px
      }
      @media screen and (min-width: 425px) {

      }
      @media screen and (min-width: 768px) {
        font-size: 26px;
        line-height: 34px
      }
      @media screen and (min-width: 1024px) {
        width: 850px;
        font-size: 30px;
        line-height: 34px
      }
      @media screen and (min-width: 1440px) {
        width: 1075px;
        font-size: 34px;
        line-height: 40px
      }
      strong {
        font-weight: 700;
      }
    }
    footer {
      //@media screen and (max-width: 320px) {
      //  position: fixed;
      //  bottom: 0;
      //  left: 0;
      //  right: 0;
      //}
      ul {
        display: flex;
        flex-direction: row;
        padding-top: 50px;
        @media screen and (max-width: 320px) {
          padding: 50px 15px 0 15px;
          box-sizing: border-box;
        }
        .logoLink {
          width: 172px;
          height: 85px;
          margin-right: 40px;
          @media screen and (max-width: 768px) {
            width: 33%;
            margin-right: 0;
          }
          a {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            img {
              margin: 0;
              position: absolute;
              top: 50%;
              left: 50%;
              -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              @media screen and (max-width: 768px) {
                width: 90%;
                position: relative;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }
  .realisationsWrapper{
    width: 100%;
    height: 800px;
    position: fixed;
    display: flex;
    flex-direction: row;
    right: -12000px;
  }
  .photosWrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    color: white;
    bottom: 0;
    right: -12000px;
    opacity: 0;
    transition: opacity 500ms, right 500ms ease-out;
    &.visible{
      right: 0;
      opacity: 1;
      top:  150px;
      overflow-y: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      @media screen and (min-width: 768px) {

      }
      @media screen and (min-width: 1024px) {
        left: 170px;
        height: 90vh;
        top: 10vh;
      }
      @media screen and (min-width: 1440px) {
        left: 300px;
        height: 700px;
      }
      @media screen and (min-width: 1920px) {
        top: 10vh;
        height: 90vh;
      }
      left: 0px;
    }
    .photosNav{
      display: none;
      flex-direction: row;
      bottom: -3000px;
      position: relative;
      padding-bottom: 50vh;
      &.photosNavVisible{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        bottom: 0;
        @media screen and (min-width: 320px) {

        }
        @media screen and (min-width: 425px) {

        }
        @media screen and (min-width: 768px) {
          width: 700px;
          margin: 0 auto;
          flex-direction: row;
          padding-bottom: 0;
          top:15vh;
        }
        @media screen and (min-width: 1024px) {
          margin: 0;
          width: 790px;
        }
        @media screen and (min-width: 1440px) {
          width: 1080px;
        }
        @media screen and (min-width: 1920px) {
          width: 1400px;
        }
      }
      .photosNavItem{
        margin: 0 auto;
        width: 85%;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%) contrast(110%);
        margin-bottom: 25px;
        @media screen and (min-width: 768px) {
          margin-bottom: 0;
        }
        @media screen and (min-width: 1024px) {
          width: 30%;
        }
        &:hover{
          -webkit-filter: grayscale(0);
          filter: grayscale(0) contrast(100%);
          animation: floater_a 8s infinite;
          svg{
            width: 75px!important;
            height: 75px!important;
          }
        }
        a{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          position: relative;
          h2{
            font-family: "clash_display";
            font-weight: 700;
            font-size: 30px;
            text-transform: uppercase;
            color: $white;
            margin-bottom: 10px;
            @media screen and (min-width: 768px) {
              font-size: 32px;
            }
            @media screen and (min-width: 1024px) {
              font-size: 30px;
            }
            @media screen and (min-width: 1440px) {
              font-size: 45px;
            }
          }
          h3{
            font-family: "clash_display";
            font-weight: 400;
            font-size: 20px;
            text-transform: uppercase;
            color: $white;
            margin-bottom: 5px;
            @media screen and (min-width: 768px) {
              font-size: 22px;
            }
            @media screen and (min-width: 1024px) {
              font-size: 20px;
            }
            @media screen and (min-width: 1440px) {
              font-size: 25px;
            }
          }
          p{
            font-family: "HelveticaNeue";
            font-weight: 100;
            font-size: 16px;
            margin-bottom: 15px;
            color: $white;
            width: 75%;
            @media screen and (min-width: 768px) {
              font-size: 13px;
            }
            @media screen and (min-width: 1024px) {
              font-size: 13px;
            }
            @media screen and (min-width: 1440px) {
              font-size: 15px;
            }
          }
          .photosNavItemBottomWrapper{
            position: relative;
            display: block;
            img{
              width: 100%;
            }
            svg{
              position: absolute;
              bottom: 25px;
              right: 25px;
              z-index: 99;
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }
    .photosItem{
      display: none;
      flex-direction: column;
      bottom: -3000px;
      position: relative;
      overflow-y: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
      &.photosItemVisible{
        display: flex;
        bottom: 0;
      }
      .typeTabs{
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        .typeTabsNAv{
          display: flex;
          flex-direction: row;
          margin-left: 0;
          margin-bottom: 25px;
          @media screen and (min-width: 320px) {

          }
          @media screen and (min-width: 425px) {

          }
          @media screen and (min-width: 768px) {

          }
          @media screen and (min-width: 1024px) {
            margin-left: -300px;
          }
          @media screen and (min-width: 1440px) {

          }
          .typeTab{
            &:nth-child(even) a{
              background-color: #000;
              color: #fff;
            }
            a{
              width: 120px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: white;
              font-family: "clash_display";
              text-transform: uppercase;
              font-weight: 700;
              font-size: 12px;
              color: #000;
              @media screen and (min-width: 320px) {

              }
              @media screen and (min-width: 425px) {

              }
              @media screen and (min-width: 768px) {
                width: 250px;
                height: 75px;
                font-size: 25px;
              }
              @media screen and (min-width: 1024px) {

              }
              @media screen and (min-width: 1440px) {

              }
            }
          }
        }
      }
      .photoCaroussel{
        display: flex;
        flex-direction: row;
        text-align: center;
        justify-content: center;
        @media screen and (min-width: 320px) {

        }
        @media screen and (min-width: 425px) {

        }
        @media screen and (min-width: 768px) {

        }
        @media screen and (min-width: 1024px) {
          margin-left: -230px;
        }
        @media screen and (min-width: 1440px) {
          margin-left: -300px;
        }
        .catCAroussel{
          padding-bottom: 50vh;
          li{
            display: flex;
            flex-direction: row;
            background-color: #000;
            &:hover{
              .tuileTitle{
                h3{
                  font-weight: 400;
                }
                h4{
                  font-weight: 400;
                }
              }
            }
            &:nth-child(even){
              background-color: #fff;
              .tuileTitle a{
                color: #000;
              }
            }
            .tuileTitle{
              width: 100px;
              height: 100px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              text-align: center;
              cursor: pointer;
              @media screen and (min-width: 425px) {
                width: 130px;
                height: 130px;
              }
              @media screen and (min-width: 768px) {
                width: 200px;
                height: 200px;
              }
              @media screen and (min-width: 1024px) {
                width: 260px;
                height: 260px;
              }
              @media screen and (min-width: 1440px) {
                width: 300px;
                height: 300px;
              }
              a{
                color: #fff;
                h3{
                  font-family: "clash_display";
                  text-transform: uppercase;
                  font-weight: 700;
                  font-size: 12px;
                  @media screen and (min-width: 320px) {

                  }
                  @media screen and (min-width: 425px) {
                  }
                  @media screen and (min-width: 768px) {
                    font-size: 26px;
                  }
                  @media screen and (min-width: 1024px) {

                  }
                  @media screen and (min-width: 1440px) {
                    font-size: 30px;
                  }
                }
                h4{
                  font-family: "clash_display";
                  text-transform: uppercase;
                  font-weight: 700;
                  font-size: 8px;
                  @media screen and (min-width: 320px) {

                  }
                  @media screen and (min-width: 425px) {
                  }
                  @media screen and (min-width: 768px) {
                    font-size: 13px;
                  }
                  @media screen and (min-width: 1024px) {

                  }
                  @media screen and (min-width: 1440px) {
                    font-size: 15px;
                  }
                }
              }
            }
            .tuileImage{
              width: 100px;
              height: 100px;
              @media screen and (min-width: 425px) {
                width: 130px;
                height: 130px;
              }
              @media screen and (min-width: 768px) {
                width: 200px;
                height: 200px;
              }
              @media screen and (min-width: 1024px) {
                width: 260px;
                height: 260px;
              }
              @media screen and (min-width: 1440px) {
                width: 300px;
                height: 300px;
              }
              a{
                width: 100%;
                display: block;
                img{
                  width: 100%;
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    .slick-list{
      background-color: #000;
    }
    .photosCat{
      display: none;
      flex-direction: column;
      bottom: -3000px;
      position: relative;
      &.photosCatVisible{
        display: flex;
        bottom: 0;
        @media screen and (min-width: 320px) {

        }
        @media screen and (min-width: 425px) {

        }
        @media screen and (min-width: 768px) {
          top: 40px;
        }
        @media screen and (min-width: 1024px) {
          top: 60px;
        }
        @media screen and (min-width: 1440px) {
          top: 80px;
        }
        .photosCatItem{
          opacity: 1;
          margin-bottom: 50vh;
          @media screen and (min-width: 1024px) {
            margin-bottom: 0;
          }
        }
      }
      .photosCatItem{
        opacity: 0;
        width: 96%;
        margin: 0 auto;
        @media screen and (min-width: 1024px) {
          width: 70%;
          margin: 0;
        }
        @media screen and (min-width: 1440px) {
          width: 60%;
        }
        @media screen and (min-width: 1930px) {
          width: 55%;
        }
        .catDescription{
          background-color: #000;

          @media screen and (min-width: 425px) {

          }
          @media screen and (min-width: 768px) {
            padding: 20px 25px;
          }
          @media screen and (min-width: 1024px) {
            padding: 40px 50px;
          }
          @media screen and (min-width: 1440px) {

          }
          h1 {
            //text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
            -webkit-text-stroke: 1px #fff; /* width and color */
            text-stroke: 1px #fff; /* width and color */
            color: black;
            font-family: "clash_display";
            font-weight: 700;
            font-size: 25px;
            padding: 15px 0;
            text-align: center;
            text-transform: uppercase;
            @media screen and (min-width: 425px) {

            }
            @media screen and (min-width: 768px) {

            }
            @media screen and (min-width: 1024px) {
              font-size: 50px;
              text-align: left;
              padding: 0;
              margin-bottom: 40px;
            }
            @media screen and (min-width: 1440px) {

            }
          }
          p{
            font-family: "clash_display";
            font-size: 12px;
            padding: 15px;
            @media screen and (min-width: 425px) {

            }
            @media screen and (min-width: 768px) {

            }
            @media screen and (min-width: 1024px) {
              font-size: 16px;
            }
            @media screen and (min-width: 1440px) {

            }
          }
        }
        .photoSliderItem{
          img{
            width: 100%;
            height: auto;
            position: relative;
            top: 1px;
          }
        }
        .photoSlider{
          @media screen and (min-width: 425px) {

          }
          @media screen and (min-width: 768px) {

          }
          @media screen and (min-width: 1024px) {

          }
          @media screen and (min-width: 1440px) {

          }
          .slick-disabled{
            display: none !important;
          }
          .slick-next{
            width: 45px;
            height: 45px;
            bottom: auto;
            right: 5px;
            &:before {
              content: '';
            }
          }
          .slick-prev{
            &:before {
              content: '';
            }
            width: 45px;
            height: 45px;
            bottom: auto;
            z-index: 999999;
            left: 5px;
            transform: rotate(180deg)translateY(25px);
          }
        }
      }
    }
  }
  .videosWrapper {
    width: 100%;
    height: 800px;
    position: fixed;
    display: flex;
    flex-direction: row;
    right: -12000px;
    opacity: 0;
    transition: opacity 500ms, right 500ms ease-out;
    &.visible {
      right: 0;
      opacity: 1;
      top: 165px;
      @media screen and (min-width: 1024px) {
        left: 220px;
      }
    }
    &.splitted {
      width: 100%;
      right: 0;
      top: 140px;
      @media screen and (min-width: 320px) {

      }
      @media screen and (min-width: 425px) {

      }
      @media screen and (min-width: 768px) {

      }
      @media screen and (min-width: 1024px) {
        width: 855px;
        height: 84vh;
        right: 60px;
      }
      @media screen and (min-width: 1440px) {
        width: 1200px;
        height: 84vh;
        right: 60px;
      }
      @media screen and (min-width: 1780px) {
        width: 1520px;
      }
      .videosNav {
        width: 50%;
        height: 70%;
        position: relative;
        top: 25%;
        .typo_home_1 {
          font-size: 70px;
          @media screen and (max-width: 320px) {
            font-size: 10px;
          }
        }
        .typo_home_1 {
        font-size: 10px;
        @media screen and (min-width: 320px) {
          font-size: 20px;
        }
        @media screen and (min-width: 425px) {
          font-size: 30px;
        }
        @media screen and (min-width: 768px) {
          font-size: 40px;
        }
        @media screen and (min-width: 1024px) {
          font-size: 50px;
        }
         @media screen and (min-width: 1440px) {
          font-size: 70px;
        }
      }
      .typo_home_2 {
        font-size: 30px;
        @media screen and (min-width: 320px) {
          font-size: 40px;
        }
        @media screen and (min-width: 425px) {
          font-size: 50px;
        }
        @media screen and (min-width: 768px) {
          font-size: 60px;
        }
        @media screen and (min-width: 1024px) {
          font-size: 80px;
        }
        @media screen and (min-width: 1440px) {
          font-size: 110px;
        }
      }
      .typo_home_3 {
        font-size: 35px;
        @media screen and (min-width: 320px) {
          font-size: 45px;
        }
        @media screen and (min-width: 425px) {
          font-size: 55px;
        }
        @media screen and (min-width: 768px) {
          font-size: 65px;
        }
        @media screen and (min-width: 1024px) {
          font-size: 85px;
        }
        @media screen and (min-width: 1440px) {
          font-size: 115px;
        }
      }
      .typo_home_4 {
        font-size: 40px;
        @media screen and (min-width: 320px) {
          font-size: 50px;
        }
        @media screen and (min-width: 425px) {
          font-size: 60px;
        }
        @media screen and (min-width: 768px) {
          font-size: 70px;
        }
        @media screen and (min-width: 1024px) {
          font-size: 90px;
        }
        @media screen and (min-width: 1440px) {
          font-size: 120px;
        }
      }
      .typo_home_5 {
        font-size: 45px;
        @media screen and (min-width: 320px) {
          font-size: 55px;
        }
        @media screen and (min-width: 425px) {
          font-size: 65px;
        }
        @media screen and (min-width: 768px) {
          font-size: 75px;
        }
        @media screen and (min-width: 1024px) {
          font-size: 95px;
        }
        @media screen and (min-width: 1440px) {
          font-size: 125px;
        }
      }
      }
      .videosContent {
        width: 50%;
      }
    }
    .videosNav {
      display: flex;
      flex-direction: column;
      //padding-bottom: 35px;
      height: 80vh;
      width: 100%;
      overflow-y: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
      &.splitted {
        display: none;
        @media screen and (min-width: 1024px) {
            display: flex;
          &.bigVideoIsOpen{
          display: none;
          }
        }
      }
      .typo_home {
        font-family: "clash_display";
        font-weight: 700;
        text-transform: uppercase;
        word-spacing: 1px;
        color: white;
        line-height: 75%;
        padding-bottom: 30px;
        padding-left: 15px;
        @media screen and (min-width: 768px) {
          padding-left: 25px;
        }
        a, a:visited {
          color: white;
        }
        a:hover {
          cursor: pointer;
          font-weight: 300;
        }
      }
      .typo_home_1 {
        font-size: 60px;
        @media screen and (min-width: 320px) {
          font-size: 60px;
        }
        @media screen and (min-width: 425px) {
          font-size: 72px;
        }
        @media screen and (min-width: 768px) {
          font-size: 80px;
        }
        @media screen and (min-width: 1024px) {
          font-size: 90px;
        }
        @media screen and (min-width: 1440px) {
          font-size: 110px;
        }
      }
      .typo_home_2 {
        font-size: 60px;
        @media screen and (min-width: 320px) {
          font-size: 60px;
        }
        @media screen and (min-width: 425px) {
          font-size: 70px;
        }
        @media screen and (min-width: 768px) {
          font-size: 80px;
        }
        @media screen and (min-width: 1024px) {
          font-size: 90px;
        }
        @media screen and (min-width: 1440px) {
          font-size: 110px;
        }
      }
      .typo_home_3 {

        font-size: 70px;
        @media screen and (min-width: 320px) {
          font-size: 70px;
        }
        @media screen and (min-width: 425px) {
          font-size: 72px;
        }
        @media screen and (min-width: 768px) {
          font-size: 80px;
        }
        @media screen and (min-width: 1024px) {
          font-size: 100px;
        }
        @media screen and (min-width: 1440px) {
          font-size: 120px;
        }
      }
      .typo_home_4 {
        font-size: 58px;
        @media screen and (min-width: 320px) {
          font-size: 76px;
        }
        @media screen and (min-width: 425px) {
          font-size: 86px;
        }
        @media screen and (min-width: 768px) {
          font-size: 90px;
        }
        @media screen and (min-width: 1024px) {
          font-size: 110px;
        }
        @media screen and (min-width: 1440px) {
          font-size: 130px;
        }
      }
      .typo_home_5 {
        font-size: 60px;
        @media screen and (min-width: 320px) {
          font-size: 60px;
        }
        @media screen and (min-width: 425px) {
          font-size: 75px;
        }
        @media screen and (min-width: 768px) {
          font-size: 80px;
        }
        @media screen and (min-width: 1024px) {
          font-size: 90px;
        }
        @media screen and (min-width: 1440px) {
          font-size: 100px
        }
      }
    }
    .videosContent {
      display: none;
      opacity: 0;
      &.bigVideoIsOpen{
        display: none!important;
      }
      &.splitted {
        display: flex;
        opacity: 1;
        height: 70vh;
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
        width: 90%;
        margin: 0 auto;
        @media screen and (min-width: 320px) {

        }
        @media screen and (min-width: 425px) {

        }
        @media screen and (min-width: 768px) {
          width: 75%;
          margin: 0 auto;
        }
        @media screen and (min-width: 1024px) {
          width: 50%;
          height: 95%;
        }
        @media screen and (min-width: 1440px) {

        }
      }
      .fullVideoContent {
        width: 100%;
        @media screen and (max-width: 320px) {
          width: 100%;
        }
        > video {
          width: 100%;

        }
        .videoCard {
          border-left: 1px solid $white;
          padding-left: 40px;
          padding-bottom: 15px;
          @media screen and (max-width: 1023px) {
            padding: 0 15px;
            box-sizing: border-box;
            width: 100%;
            border-left: none;
          }
        }
        h2 {
          font-size: 35px;
          margin-bottom: 10px;
          font-family: "clash_display";
          font-weight: 700;
          text-transform: uppercase;
          @media screen and (max-width: 320px) {
            font-size: 25px;
          }
        }
        .shortVideosDisclaimer {
          font-size: 15px;
          font-family: "clash_display";
          font-weight: 400;
          margin-bottom: 40px;
          cursor: pointer;
          @media screen and (min-width: 320px) {

          }
          @media screen and (min-width: 425px) {

          }
          @media screen and (min-width: 768px) {

          }
          @media screen and (min-width: 1024px) {
            font-size: 20px;
          }
          @media screen and (min-width: 1440px) {
            font-size: 28px;
          }
          span {
            text-decoration: underline;
          }
        }

        .distribution {
          font-family: "HelveticaNeue";
          font-weight: 100;
          font-size: 15px;
          margin-bottom: 15px;
          white-space: pre;
          @media screen and (min-width: 1024px) {
            font-size: 16px;
          }
          @media screen and (min-width: 1440px) {
            font-size: 20px;
          }
        }
        .description {
          font-family: "clash_display";
          font-weight: 400;
          font-size: 18px;
          @media screen and (min-width: 1024px) {
            font-size: 20px;
          }
          @media screen and (min-width: 1440px) {
            font-size: 28px;
          }
        }
        .videoThumbnail{
          width: 100%;
          margin-bottom: 25px;
          cursor: pointer;
          position: relative;
          .playLogo{
            position: absolute;
            z-index: 10;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            width: 50px;
            height: 50px;
          }
          img{
            width:100%;
          }
        }
        .videoDescBlock {
          display: flex;
          @media screen and (max-width: 1023px) {
            flex-direction: column;
          }
          .shortVideos{
            padding-right: 0;
            flex: 1;
            @media screen and (min-width: 1024px) {
              padding-right: 10px;
            }
            @media screen and (min-width: 1440px) {
              padding-right: 40px;
            }
            .listShortVideos{
              @media screen and (max-width: 1023px) {
                padding-right: 15px;
                display: flex;
                overflow-x: scroll;
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */
                &::-webkit-scrollbar {
                  display: none;
                }
              }
              li{
                cursor: pointer;
                @media screen and (max-width: 1023px) {
                  padding-left: 15px;
                }
                margin-bottom: 25px;
                img{
                  width: 100px;
                  @media screen and (min-width: 320px) {
                  }
                  @media screen and (min-width: 425px) {
                  }
                  @media screen and (min-width: 768px) {
                    width: 110px;
                  }
                  @media screen and (min-width: 1024px) {
                    width: 140px;
                  }
                  @media screen and (min-width: 1440px) {
                    width: 180px;
                  }
                }
                video {
                  width: 250px;
                  margin: 15px;
                }
              }
            }
          }
        }
        .mobileSiblingsMenu{
          padding: 0 15px;
          @media screen and (min-width: 1024px) {
            display: none;
          }
          > div{
            display: flex;
            font-family: "clash_display";
            text-transform: uppercase;
            color: #fff;
            padding-top: 25px;
            border-top: 1px solid #fff;
            margin-top: 15px;
            > div{
              flex: 1;
              a{
                color: #fff;
                font-weight: 700;
                width: 100%;
                height: 25px ;
                display: block;
                font-size: 12px;
              }
            }
          }
          .mobileSiblingsMenuPrev{
            display: flex;
            justify-content: flex-start;
            > div{
              font-size: 15px;
              line-height: 25px;
            }
            .mobileSiblingsMenuNextLogo{
              margin-right: 15px;
              font-size: 45px;
              font-weight: 100;
            }
          }
          .mobileSiblingsMenuHome{
            text-align: center;
            font-size: 15px;
            line-height: 25px;
          }
          .mobileSiblingsMenuNext{
            display: flex;
            justify-content: flex-end;
            > div{
              font-size: 15px;
              line-height: 25px;
            }
            .mobileSiblingsMenuNextLogo{
              margin-left: 15px;
              font-size: 45px;
              font-weight: 100;
            }
          }
        }
      }
    }
    .bigVideoViewer{
      display: none;
      width: 80%;
      margin: 0 auto;
      @media screen and (max-width: 1023px) {
        position: relative;
      }
      video{
        width: 100%;
      }
      .videoDescription{
        display: flex;
        font-family: "clash_display";
        text-transform: uppercase;
        padding-top: 10px;
        justify-content: space-between;
        @media screen and (max-width: 320px) {
          flex-direction: column;
        }
        .videoDescriptionTitle{
          font-weight: 700;
          font-size: 35px;
          @media screen and (max-width: 320px) {
            font-size: 24px;
          }
        }
        .videoDescriptionDirctor{
          font-weight: 300;
          font-size: 35px;
          @media screen and (max-width: 320px) {
            font-size: 15px;
          }
        }
      }
      .closeLogo{
        width: 50px;
        height: 50px;
        position: fixed;
        top: 10px;
        right: 10px;
        @media screen and (max-width: 1023px) {
          position: absolute;
          width: 25px;
          height: 25px;
          top: 0;
          right: 0;
        }
      }
      &.bigVideoIsOpen{
        display: block;

      }
    }
  }
}