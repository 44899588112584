.big_video {
    display: inline-table;
    position: fixed;
    opacity: 0;
    width: 100vw;
    height: 100vh;
    video{
      min-width: 100% ;
      min-height: 100% ;
      position: fixed;
      top: 20000px;
      transform: translate(-50%, -50%);
      left: 50%;
      transition: top 1000ms ease-out;
    }
    .video_overlay{
      min-width: 100% ;
      min-height: 100% ;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      position: absolute;
      z-index: 9998;
    }
  .video_close{
    @media screen and (min-width: 320px) {
      top: 25px;
    }
    @media screen and (min-width: 425px) {
      font-size: 20px;
      top: 35px;
      right: 50px;
    }
    @media screen and (min-width: 768px) {
      top: 45px;
      right: 100px;
    }
    @media screen and (min-width: 1024px) {
      top: 55px;
    }
    @media screen and (min-width: 1440px) {
      top: 85px;
    }
    font-family: "clash_display";
    font-size: 15px;
    right: 15px;
    top: 15px;
    position: absolute;
    z-index: 9999;
    cursor: pointer;
  }
  &.big_video_is_visible.home{
    top: 0;
    opacity: 1;
    video{
      top: 50%;
    }
  }
  .videoBtn{
    color: #fff;
    font-family: "clash_display";
    font-weight: 700;
    font-size: 25px;
    text-align: center;
    padding-top: 10px;
    cursor: pointer;
  }
  &.hide_big_video{
    display: none;
    opacity: 0;
  }
}


