.mobile_menu{
  display: none;
  position: fixed;
  top: 25px;
  right: 15px;
  width: 30px;
  svg{
    width: 100%;
    height: auto;
  }
}
.page .mobile_menu_is_visible{
  display: block;
  @media screen and (min-width: 1024px) {
    display: none;
  }
}

aside{
  width: 90%;
  margin: 0 auto;
  height: 100px;
  border-right: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -15000px;
  transition: all 500ms, opacity 500ms;
  @media screen and (min-width: 320px) {

  }
  @media screen and (min-width: 425px) {

  }
  @media screen and (min-width: 768px) {

  }
  @media screen and (min-width: 1024px) {
    width: 150px;
    height: 100%;
    border-right: 1px solid #fff;
  }
  @media screen and (min-width: 1440px) {
    width: 215px;
  }
  &.page {
    left: 5%;
    @media screen and (min-width: 1024px) {
      left: 0;
    }
  }
  .logo_min{
    margin-top: 25px;
    margin-left: 0;
    width:70px;
    height: auto;
    @media screen and (min-width: 1024px) {
      margin-top: 80px;
      margin-left: 40px;
      width: 90px;
    }
    @media screen and (min-width: 1440px) {
      margin-left: 65px;
    }
    svg{
      width: 100%;
      height: auto;
    }
  }
  .return_link{
    font-family: "clash_display";
    font-weight: 500;
    line-height: 40px;
    color: #FFF;
    font-size: 12px;
    padding-left: 20px;
    cursor: pointer;
  }
  .aside_nav{
    transform: none;
    position: absolute;
    bottom: 350px;
    width: 100%;
    @media screen and (min-width: 1024px) {
      position: absolute;
      bottom: 100px;
      transform: rotate(270deg);
    }
    ul{
      display: flex;
      justify-content: flex-end;
      @media screen and (min-width: 1024px) {
        justify-content: flex-start;
      }
      li{
        margin-right: 0;
        margin-left: 10px;
        a{
          font-family: "clash_display";
          font-weight: 500;
          line-height: 40px;
          color: $light_blue;
          text-transform: lowercase;
          font-size: 14px;
          @media screen and (min-width: 320px) {

          }
          @media screen and (min-width: 425px) {
            font-size: 16px;
          }
          @media screen and (min-width: 768px) {
            font-size: 18px;
          }
          @media screen and (min-width: 1024px) {
            font-size: 30px;
          }
          @media screen and (min-width: 1440px) {

          }
          &.active {
            color: $white;
          }
          &:hover {
            color: $white;
          }
        }
      }
    }
  }
  &.mobile_menu_is_open{
    border-bottom: 1px solid #fff;
    .aside_nav{
      bottom: 0;
      transition: all 500ms;
    }
  }
}
