nav {
  position: fixed;
  width: 1440px;
  height: 425px;
  right: -100px;
  transition: all 500ms, opacity 500ms;
  left: 15000px;
  top: 130px;
  @media screen and (min-width: 320px) {
    top: 190px;
  }
  @media screen and (min-width: 425px) {
    top: 240px;
  }
  @media screen and (min-width: 1024px) {
    top: 300px;
  }
  @media screen and (min-width: 1440px) {
    bottom: 144px;
    top: auto;
  }
  &.big_on_front {
    left: 25px;
    @media screen and (min-width: 1024px) {
      left: 115px;
    }
  }
  &.page {
    opacity: 0;
  }
  &.nav_photos_videos{
    right: -10000px;
    &.nav_photos_videos_on{
      left: 25px;
      right: 0;
      @media screen and (min-width: 425px) {
        left: 50px;
      }
      @media screen and (min-width: 1024px) {
        left: 200px;
      }
      @media screen and (min-width: 1440px) {
        left: 250px;
      }
    }
  }
  ul {
    li {
      a {
        font-family: "clash_display";
        font-weight: 700;
        font-size: 30px;
        line-height: 45px;
        text-transform: uppercase;
        color: $white;
        @media screen and (min-width: 320px) {
          font-size: 35px;
          line-height: 50px;
        }
        @media screen and (min-width: 425px) {
          font-size: 50px;
          line-height: 80px;
        }
        @media screen and (min-width: 768px) {
          font-size: 85px;
          line-height: 100px;
        }
        @media screen and (min-width: 1024px) {
          font-size: 85px;
          line-height: 100px;
        }
        @media screen and (min-width: 1440px) {
          font-size: 140px;
          line-height: 120px;
        }
        &:hover {
          font-family: "clash_display";
          font-weight: 300;
          transition: font-weight 100ms ease;
        }
      }
    }
  }
}