.brand_logo {
  position: fixed;
  z-index: 5;
  transition: all 500ms;
  opacity: 1;
  a{
    display: block;
    overflow: hidden;
    img{
      display: block;
      width: 100%;
    }
  }
  &.home {
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 250px;
    @media screen and (min-width: 320px) {
      width: 350px
    }
    @media screen and (min-width: 425px) {
      width: 450px
    }
    @media screen and (min-width: 768px) {
      width: 650px
    }
    @media screen and (min-width: 1024px) {
      width: 700px
    }
    @media screen and (min-width: 1440px) {
      width: 1075px;
    }
    &.logo_on{
      > a > svg {
        transform: translateX(0);
      }
    }
    > a > svg {
      position: relative;
      transform: translateX(2000px);
      transition: transform 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    }
  }
  &.top_left {
    left: 15px;
    top: 15px;
    width: 120px;
    margin-left: 0;
    margin-top: 0;
    transform: none;
    @media screen and (min-width: 320px) {
      left: 20px;
      top: 25px;
      width: 140px;
    }
    @media screen and (min-width: 425px) {
      left: 30px;
      top: 35px;
      width: 160px;
    }
    @media screen and (min-width: 768px) {
      left: 40px;
      top: 45px;
      width: 180px;
    }
    @media screen and (min-width: 1024px) {
      left: 50px;
      top: 55px;
      width: 200px;
    }
    @media screen and (min-width: 1440px) {
      left: 70px;
      top: 85px;
      width: 248px;
    }
  }
  &.page {
    opacity: 0;
  }
}