/**
 * ----------------------------------------
 * animation translate logo letters on start
 * ----------------------------------------
 */
@keyframes logo_start {
  0% {
    right: -85%;
  }
  100% {
    right: 0;
  }
}
@-webkit-keyframes logo_start {
  0% {
    right: -85%;
  }
  100% {
    right: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * home record
 * ----------------------------------------
 */
@keyframes clignoter {
  0%   { fill: red }
  50%   {fill: #838383 }
  100% { fill: red }
}

/**
 * ----------------------------------------
 * image item floater
 * ----------------------------------------
 */
@keyframes floater_a {
  25%{transform: translateY(-5%);transition: ease 0.5s;}
  75%{transform: translateY(5%);transition: ease 0.5s;}
}
@keyframes floater_b {
  0%{transform: translateY(-3%);transition: ease 0.5s;}
  50%{transform: translateY(2%);transition: ease 0.5s;}
}
@keyframes floater_c {
  0%{transform: translateY(-2%);transition: ease 0.5s;}
  50%{transform: translateY(6%);transition: ease 0.5s;}
}